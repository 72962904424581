import * as React from "react";
import Seo from "./../components/Seo";
import Title from "./../components/Title";
import Photo from "./../components/Photo";
import "./styles.scss";

const IndexPage = () => (
  <>
    <Seo title="Home" />;
    <section id="home-page" className="section is-medium">
      <div className="columns is-vcentered is-centered reverse-columns">
        <div className="column is-7 is-10-mobile">
          <Title text="Hey, I'm Jonathan." />
          <p className="subtitle">
            I use technology to solve problems and turn dreams into reality.
          </p>
        </div>
        <div className="column is-3 is-half-mobile">
          <Photo />
        </div>
      </div>
      {/* 
      <p>craft, design, create, tinker, code, develop, build</p>
      <p>interactive, creative, persuasive, responsive, exceptional, clean, user-friendly, stunning, beautiful, functional, performant, accessible, efficient, simple, effective, impactful, innovation, strategy, functional</p>
      <p>bring to life, solve problems, ideas into reality</p> 
    */}
    </section>
  </>
);

export default IndexPage;
