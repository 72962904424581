import * as React from "react";
import "./Title.scss";

const Title = ({ text }) => (
  <h1 className="title title-with-background">
    <div>{text}</div>
  </h1>
);

export default Title;
