import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Photo = () => (
  <StaticImage
    src="./../assets/profile_green_round.png"
    alt="profile green background"
    placeholder="blurred"
    loading={"eager"}
    style={{ borderRadius: "50%" }}
    width={200}
    height={200}
  />
);

export default Photo;
